<template>
  <line-chart :chart-data='chartData' :height="height"></line-chart>
</template>

<script>
import LineChart from './LineChart';

export default {
  name: 'LineWrapper',
  components: {
    LineChart,
  },
  props: {
    labels: {
      type: Array,
    },
    datasets: {
      type: Array,
    },
    height: {
      type: Number,
      default: 120,
    },
  },
  computed: {
    chartData() {
      return {
        fill: 'start',
        labels: this.labels,
        datasets: this.datasets,
      };
    },
  },
};
</script>

<style scoped></style>
