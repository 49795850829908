import { mapActions, mapGetters } from 'vuex';

export default {
  created() {
    this.getIndicators();
  },
  watch: {
    country(val) {
      if (this.dataset) this.dataset.country = val;
      this.fetchIndicators({ name: '', category: this.category || this.dataset?.category, country: val });
    },
    country2(val) {
      if (this.dataset2) this.dataset2.country = val;
      this.fetchIndicators({ name: '', category: this.category2 || this.dataset2?.category, country: val });
    },
    category(val) {
      const { $route: r } = this;
      this.fetchIndicators({ name: '', category: val, country: this.country || this.dataset?.country });
      if (this.$route.name === 'Search' && val !== r.query.category) {
        const { query } = r;
        const newQuery = { ...query, category: val };
        this.$router.replace({ ...r, query: newQuery });
      }
      if (this.dataset) {
        this.dataset.category = val;
      }
    },
    category2(val) {
      this.fetchIndicators({ name: '', category: val, country: this.country2 || this.dataset2?.country });
      this.dataset2.category = val;
    },
    indicator(val) {
      const { $route: r } = this;
      if (this.$route.name === 'Search' && val !== r.query.indicator) {
        const { query } = r;
        const newQuery = { ...query, indicator: val };
        this.$router.replace({ ...r, query: newQuery });
      }
    },
  },
  computed: {
    ...mapGetters({ getIndicatorList: 'indicators/asOptions', categories: 'indicators/categoryOptions' }),
    indicators() {
      return this.getIndicatorList(this.category || '', this.country || '');
    },
    indicators2() {
      return this.getIndicatorList(
        this.category2 || '',
        this.country2 || '',
        this.frequency || this.dataset.frequency,
      );
    },
  },
  methods: {
    ...mapActions({
      getIndicators: 'indicators/getInitialIndicators',
      fetchIndicators: 'indicators/fetchIndicatorsWith',
    }),
  },
};
