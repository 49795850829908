import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';
import EventBus from '@/utils/event-bus';
import { fetchProfile } from '@/api';

import Components from '../views/Components.vue';
import About from '../views/Website/About/About.vue';
import Contact from '../views/Website/Contact/Contact.vue';
import Blog from '../views/Website/Blog/Blog.vue';
import Home from '../views/Website/Home/Home.vue';
import SingleBlogView from '../views/Website/Blog/Single/SingleBlog.vue';
import Signup from '../views/Auth/Signup/Signup.vue';
import Login from '../views/Auth/Login/Login.vue';
import OneTimePassword from '../views/Auth/Signup/otp.vue';
import ForgotPassword from '../views/Auth/ForgotPassword/ForgotPassword.vue';
import ResetPassword from '../views/Auth/ResetPassword/ResetPassword.vue';
import VerificationCode from '../views/Auth/ForgotPassword/VerificationCode.vue';
import Profile from '../views/Profile/Profile.vue';
import Search from '../views/Search/Search/Search.vue';
import Results from '../views/Search/Results/Results.vue';
import SingleResult from '../views/Search/SingleResult/SingleResult.vue';
import CompareData from '../views/Search/CompareData/CompareData.vue';
import SelectCountry from '../views/CountryDashboard/SelectCountry/SelectCountry.vue';
import CountryDashboard from '../views/CountryDashboard/Dashboard/Dashboard.vue';
import KwertyInstitutions from '../views/Website/KwertyFor/KwertyForInstitutions.vue';
import KwertyLeader from '../views/Website/KwertyFor/KwertyForLeader.vue';
import KwertyLearning from '../views/Website/KwertyFor/KwertyForLearning.vue';
import KwertyLegal from '../views/Website/Legal/Legal.vue';
import Careers from '../views/Website/Careers/Careers.vue';
import JobListing from '../views/Website/Careers/JobListing.vue';
import SingleChart from '../views/Embed/SingleChart.vue';
import CompareChart from '../views/Embed/CompareChart.vue';
import NotFound from '../views/NotFound/404.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/components',
    name: 'Components',
    component: Components,
  },
  // website
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about-us',
    name: 'About',
    component: About,
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/careers',
    name: 'Careers',
    component: Careers,
  },
  {
    path: '/careers/:slug',
    name: 'JobListing',
    component: JobListing,
  },
  {
    path: '/blog/:slug(.*)',
    name: 'SingleBlog',
    component: SingleBlogView,
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog,
  },
  {
    path: '/kwerty-for-institutions',
    name: 'KwertyInstitutions',
    component: KwertyInstitutions,
  },
  {
    path: '/kwerty-for-learning',
    name: 'KwertyLearning',
    component: KwertyLearning,
  },
  {
    path: '/kwerty-for-leaders',
    name: 'KwertyLeader',
    component: KwertyLeader,
  },
  {
    path: '/legal',
    name: 'KwertyLegal',
    component: KwertyLegal,
  },
  // website
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: {
      notAuth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      notAuth: true,
    },
  },
  {
    path: '/otp',
    name: 'Otp',
    component: OneTimePassword,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/verify-code',
    name: 'VerificationCode',
    component: VerificationCode,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      auth: true,
    },
  },
  // main
  {
    path: '/search',
    name: 'Search',
    component: Search,
  },
  {
    path: '/results',
    name: 'Results',
    component: Results,
  },
  {
    path: '/indicators/:indicator/:country?',
    name: 'SingleResult',
    component: SingleResult,
  },
  {
    path: '/advanced/:indicator/:country',
    name: 'AdvancedSingleResult',
    component: SingleResult,
  },
  {
    path: '/compare',
    name: 'CompareData',
    component: CompareData,
    meta: {
      auth: true,
    },
  },
  // country
  {
    path: '/country/:country',
    name: 'CountryDashboard',
    component: CountryDashboard,
    meta: {
      auth: true,
    },
  },
  {
    path: '/country',
    name: 'SelectCountry',
    component: SelectCountry,
    meta: {
      auth: true,
    },
  },
  {
    path: '/chart',
    name: 'Chart',
    component: SingleChart,
    meta: {
      hideNav: true,
    },
  }, {
    path: '/chart/compare',
    name: 'CompareChart',
    component: CompareChart,
    meta: {
      hideNav: true,
    },
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // scrollBehavior: () => ({ x: 0, y: 0 }),
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    }
    return {
      x: 0,
      y: 0,
    };
  },
  routes,
});

// const pagenotfound = {
//   path: '*',
//   component: NotFound,
// };

// Guards
export const guard = (to, from, next) => {
  const isAuthenticated = store.getters['auth/getToken'];
  if (!isAuthenticated) {
    /* If user is not authenticated
    `* and moves to a guarded route
    `* prompt them to sign in */
    const fromInApp = from.name !== null;
    if (to.meta.auth && fromInApp && !from.meta.auth) {
      EventBus.$emit('showAuthPrompt');
    } else if (to.meta.auth && !fromInApp) {
      EventBus.$emit('showAuthPrompt');
      router.push({ name: 'Login', query: { next: to.fullPath } });
    } else {
      next();
    }
  } else if (isAuthenticated) {
    if (to.meta.notAuth) {
      router.push('/search');
      EventBus.$emit('addNotification', { message: 'You\'re already logged in' });
    } else if (to.meta.auth) {
      fetchProfile();
      next();
    } else {
      next();
    }
  }
};

router.beforeEach(guard);

export default router;
