import indicatorList from '@/mixins/IndicatorList';
import { KButton, KInput } from '@/components';
import { storyblok } from '@/config';

// helpers
import countries from '@/utils/countries';
import searchSuggestions from '@/utils/searchSuggestions';

export default {
  name: 'Search',
  mixins: [indicatorList],
  components: {
    KInput,
    KButton,
  },
  data: () => ({
    showAdvanced: false,
    suggested: [],
    category: '',
    indicator: '',
    country: '',
    countries,
    value: '',
    isSearching: false,
    suggestions: [],
  }),
  created() {
    const { query } = this.$route;
    this.country = query.country || '';
    this.category = query.category || '';
    this.indicator = query.indicator || '';
  },
  computed: {
    advancedSearchReady() {
      return this.indicator && this.country;
    },
    sortedSuggestions() {
      const suggestions = this.suggestions.map((s) => s.searchTerm);
      // suggestions.sort((a, b) => String(a).localeCompare(String(b)));

      return suggestions.slice(0, 5);
    },
  },
  watch: {
    country(val) {
      const { $route: r } = this;
      if (val.toLocaleString() !== r.query.country?.toLowerCase()) {
        const newRoute = { ...r };
        newRoute.query = { ...r.query, country: val };
        this.$router.push(newRoute);
      }
    },
    showAdvanced(val) {
      const showingAdvanced = this.$route.fullPath.includes('/search')
        && this.$route.fullPath.includes('#advanced');
      if (val && !showingAdvanced) {
        this.$router.replace({ name: 'Search', hash: '#advanced' });
      } else if (!val && showingAdvanced) {
        this.$router.replace({ name: 'Search', hash: '' });
      }
    },
    async value(val) {
      searchSuggestions.call(this, val);
    },
  },
  methods: {
    goToSearch() {
      const { value, $router } = this;
      if (value.trim()) {
        $router.push({ name: 'Results', query: { q: encodeURIComponent(value.trim()) } });
      }
    },
    advancedSearch() {
      const { indicator, country, category } = this;
      this.$router.push({
        name: 'AdvancedSingleResult',
        params: { indicator, country },
        query: { category },
      });
    },
  },
  async mounted() {
    this.getIndicators();
    const {
      $route: { hash },
    } = this;
    if (hash && hash === '#advanced') {
      this.showAdvanced = true;
    }

    this.suggested = await storyblok.get('/stories?content_type=Post&per_page=5&page=1')
      .then(({ data }) => data)
      .then(({ stories }) => stories);
  },
};
