import { saveAs } from 'file-saver';
import { mapActions, mapGetters } from 'vuex';
import {
  KButton, KCard, KInput, KModal,
} from '@/components';
import BarChartWrapper from '@/components/Charts/BarChartWrapper.vue';
import LineWrapper from '@/components/Charts/LineWrapper.vue';
import DatasetResult from '@/mixins/DatasetResult';
import Analysis from '@/mixins/Analysis';

// request
import { advancedSearch, downloadCsv } from '@/api/dataset';

// helpers
import stringHelpers from '@/utils/string-helpers';
import chartTypeOptions from '@/utils/chart-type-options';
import colors from '@/utils/colors';
import EventBus from '@/utils/event-bus';
import countries from '@/utils/countries';
import GetLatestWithValue from '@/mixins/GetLatestWithValue';

import SaveAsPng from '../Save/SaveAsPng.vue';
import SaveAsPdf from '../Save/SaveAsPdf.vue';
import indicatorList from '@/mixins/IndicatorList';
import { getYear } from '@/views/Search/Save/downloadTemplate';

delete countries[''];

export default {
  name: 'SingleResult',
  mixins: [DatasetResult, Analysis, GetLatestWithValue, indicatorList],
  head: {
    title() {
      return { inner: `${this.titleCase(this.country)}: ${this.indicator} on Kwerty` };
    },
  },
  components: {
    SaveAsPng,
    KCard,
    KModal,
    LineWrapper,
    BarChartWrapper,
    KInput,
    KButton,
    SaveAsPdf,
  },
  data: () => ({
    notFound: false,
    saving: '',
    isSavingCsv: false,
    showDownloadModal: false,
    showEmbedModal: false,
    showShareModal: false,
    shareLink: '',
    show: true,
    isLoading: true,
    country: '',
    indicator: '',
    category: 'finance',
    category2: '',
    country2: '',
    frequency: '',
    dataset: {
      chartType: 'bar',
      colour: '#900F7B',
      frequency: 'yearly',
      startYear: '1960',
      endYear: '2008',
      values: null,
      metric: '',
      notes: '',
      source: '',
      image: null,
    },
    dataset2: {
      category: '',
      indicator: '',
      starYear: '',
      endYear: '',
      country: '',
    },
    compoundData: null,
    mobileOptions: {},
    customize: false,
    readyCompare: false,
    chartTypeOptions,
    years: {},
    countries,
    colourOptions: colors,
    embedWidth: 950,
    embedHeight: 450,
  }),
  async created() {
    const {
      removeSymbols: clean,
      $route: {
        params,
        query,
      },
    } = this;
    try {
      const {
        indicator,
        country,
      } = params;
      const { category } = query;
      // const id = params.id || query.id;
      // set page heading
      this.indicator = indicator;
      this.country = country;
      // await this.fetchDataset(id, { first: true });
      const { data: { data } } = await advancedSearch({
        indicator: clean(indicator),
        country: clean(country),
        category: clean(category),
      });

      if (data.dataset.length) {
        const compoundData = {};
        data.dataset.forEach((d) => {
          const indicatorMatch = indicator.toLowerCase() === d.nameOfIndicator.toLowerCase();
          const countryMatch = country.toLowerCase() === d.country.toLowerCase();
          const { isPublished } = d;
          if (indicatorMatch && countryMatch && isPublished) {
            compoundData[d.frequency] = {
              ...d,
              endYear: getYear(this.periodWithValue(d.data, d.data.length).period, d.endYear),
            };
          }
        });

        const availableFrequencies = Object.keys(compoundData);
        if (!availableFrequencies.length) {
          this.notFound = true;
        } else {
          this.compoundData = compoundData;
          this.frequency = query.frequency || Object.keys(compoundData)[0];
        }
        this.isLoading = false;
      } else {
        this.notFound = true;
      }
      await this.getDatasetOptionsFromURl();
      this.shareLink = window.location.href;
    } catch (e) {
      console.log(e.message);
    }
  },
  watch: {
    frequency(val) {
      const { $route: { query } } = this;
      const fromCompound = this.compoundData[val];
      const { startYear, endYear } = fromCompound;
      const firstPeriodWithValue = query.startYear || this.startPeriodWithValue(
        fromCompound.data,
        0,
        (startYear),
      ).period;
      const lastPeriodWithValue = query.endYear || this.periodWithValue(
        fromCompound.data,
        fromCompound.data.length,
        (endYear),
      ).period;
      const yearsArray = fromCompound.data.map((item) => getYear(item.period));
      this.dataset = {
        ...this.dataset,
        ...fromCompound,
        colour: query.colour || this.dataset.colour,
        indicator: this.indicator,
        yearsArray,
        startYear: getYear(firstPeriodWithValue),
        endYear: getYear(lastPeriodWithValue),
        chartType: query.chartType || this.dataset.chartType,
      };
    },
    $route() {
      this.shareLink = window.location.href;
    },
    dataset: {
      deep: true,
      handler(val) {
        this.mobileOptions = { ...val };
        this.updateURLSearchParams(val);
        // this.analysis = val.values ? this.getAnalysis(val) : this.analysis;
        this.show = false;
      },
    },
    show(val) {
      if (!val) {
        setTimeout(this.setShow, 0);
      }
    },
  },
  computed: {
    ...mapGetters({
      categories: 'indicators/categoryOptions',
      indicators: 'indicators/asOptions',
      isAuthenticated: 'auth/getToken',
    }),
    latest() {
      return this.periodWithValue(this.dataset.values.yearly, this.dataset.values.yearly.length);
    },
    compareDataQuery() {
      const {
        dataset: {
          colour,
          indicator,
          category,
          country,
          startYear,
          endYear,
          frequency,
          chartType,
        },
        dataset2,
      } = this;

      const query = new URLSearchParams();
      // query.append('id', id);
      query.append('colour', colour);
      query.append('startYear', startYear);
      query.append('endYear', endYear);
      query.append('indicator', indicator);
      query.append('category', category);
      query.append('country', country);
      query.append('frequency', frequency);
      query.append('chartType', chartType);

      Object.entries(dataset2)
        .forEach(([key, value]) => {
          query.append(`${key}2`, value);
        });
      query.append('colour2', '#0F2B90');

      return query.toString();
    },
    embedString() {
      const {
        dataset: {
          country,
          indicator,
          startYear,
          endYear,
          colour,
          frequency,
          chartType,
        },
        embedWidth,
        embedHeight,
      } = this;
      const { origin } = window.location;
      const query = new URLSearchParams();
      query.append('country', country);
      query.append('indicator', indicator);
      query.append('startYear', startYear);
      query.append('endYear', endYear);
      query.append('colour', colour);
      query.append('frequency', frequency);
      query.append('chartType', chartType);

      const url = `${origin}/chart?${query.toString()}`;
      return `<iframe src='${url}' style='border:0px #ffffff none;' name='Kwerty Embed' scrolling='no' frameBorder='1'marginHeight='0px' marginWidth='0px' height='${embedHeight}px' width='${embedWidth}px' allowFullScreen></iframe>`;
    },
  },
  methods: {
    ...stringHelpers,
    ...mapActions({
      fetchIndicators: 'indicators/fetchIndicatorsWith',
    }),
    updateDataset() {
      this.dataset = { ...this.dataset, ...this.mobileOptions };
      this.frequency = this.mobileOptions.frequency;
      this.customize = false;
    },
    follow({
      clientX: x,
      clientY: y,
    }) {
      const { definition } = this.$refs;
      definition.style.top = `${y}px`;
      definition.style.left = `${x}px`;
    },
    generate() {
      const canvas = document.querySelector('canvas');
      this.dataset.image = canvas.toDataURL();
    },
    saveAsPng() {
      this.showDownloadModal = false;
      this.saving = 'png';
    },
    saveAsPdf() {
      this.showDownloadModal = false;
      this.saving = 'pdf';
    },
    async saveAsCsv() {
      const { dataset } = this;
      const {
        id,
        indicator,
        country,
        currency,
        metric,
      } = dataset;
      this.isSavingCsv = true;
      try {
        const response = await downloadCsv({ id });
        if (response.error) {
          throw Error(response.error);
        }
        const data = response.data.replace('value', `value (in ${metric}${currency ? ` ${currency}` : ''})`);
        const download = new Blob([data], { type: 'text/plain;charset=UTF-8' });
        const fileName = this.titleCase(`${country} ${this.removeSymbols(indicator)}.csv`);
        saveAs(download, fileName);
        this.$toast.show({ message: ` Downloaded ${fileName}` });
        this.isSavingCsv = false;
        this.showDownloadModal = false;
      } catch (error) {
        console.log(error);
        this.isSavingCsv = false;
      }
    },
    cancelSaving() {
      this.saving = false;
    },
    setShow() {
      this.show = true;
    },
    checkAuthBefore(func) {
      this.isLoggedIn()
        .then(() => {
          func();
        })
        .catch(() => {
          EventBus.$emit('showAuthPrompt');
        });
    },
  },
};
