<template>
  <div class="container content">
    <footer class="footer">
      <div class="footer__content">
        <div class="summary">
          <img
            alt=""
            class="logo"
            src="https://one-percent-bucket-1.s3.us-west-2.amazonaws.com/kwertyassets/logo.png"
          />
          <p>
            Kwerty is building the world’s largest public data engine, layered with artificial
            intelligence, thereby enabling improved user experiences and actionable analysis.
          </p>
          <p>
            The platform leverages on multiple global open datasets and uses a clean interactive
            view to improve decision making.
          </p>
        </div>

        <div class="nav">
          <div v-for="group in menu" :key="group.title" class="menu-group">
            <h3 class="group-title">{{ group.title }}</h3>
            <ul>
              <li v-for="child in group.children" :key="child.title">
                <router-link :to="child.url">
                  {{ child.title }}
                  <span v-if="child.comingSoon" class="coming-soon">Coming Soon</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="socials">
        <a href="https://www.facebook.com/Kwertydata" target="_blank"
          ><img src="@/assets/fb.svg" alt="Kwerty on Facebook"
        /></a>
        <a href="https://twitter.com/kwerty_data" target="_blank"
          ><img src="@/assets/tw.svg" alt="Kwerty on Twitter"
        /></a>
        <a href="https://www.linkedin.com/company/kwerty" target="_blank"
          ><img src="@/assets/in.svg" alt="Kwerty on LinkedIn"
        /></a>
        <a href="https://www.instagram.com/kwerty_hq" target="_blank"
          ><img src="@/assets/ig.svg" alt="Kwerty on Instagram"
        /></a>
      </div>
    </footer>
  </div>
</template>

<script>
import menu from '@/config/menu';

export default {
  name: 'WebsiteFooter',
  data: () => ({
    menu,
  }),
};
</script>

<style lang="scss" scoped>
.footer {
  margin: 10.4rem 0;

  &__content {
    display: grid;
    grid: auto-flow max-content / 1fr;

    @media (min-width: 1024px) {
      grid-template-columns: 53.8rem auto;
      column-gap: 6.4rem;
      .summary {
        flex-basis: 46.4rem;
      }
      .nav {
        flex-basis: auto;
      }
    }
  }
}

.summary {
  max-width: 46.5rem;
  margin-bottom: 6.4rem;

  p {
    font-size: 1.4rem;
    line-height: 150%;
    &:not(:first-of-type) {
      margin-top: 1.4rem;
    }
  }
}

.nav {
  display: grid;
  grid: auto-flow max-content / 1fr;
  margin-top: 1.5rem;
  @media (min-width: 600px) {
    grid-template-columns: max-content 28rem;
    justify-content: space-between;
  }
  @media (min-width: 768px) {
    grid-template-columns: 32rem 1fr 1fr;
    justify-content: space-between;
  }
  @media (min-width: 1024px) {
    grid-template-columns: max-content auto;
    justify-content: space-between;
  }

  .menu-group {
    margin-bottom: 4.8rem;
    @media (min-width: 768px) {
      min-width: 50%;
      flex-basis: max-content;
    }

    &:last-child {
      margin-bottom: 5.6rem;
      @media (min-width: 768px) {
        margin-bottom: 3.2rem;
      }
    }

    .group-title {
      margin-bottom: 2.4rem;
      font-size: 10px;
      line-height: 12px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }

    ul {
      list-style: none;

      li {
        display: flex;
        height: 2.4rem;
        align-items: center;
        cursor: pointer;
        transform-origin: 0 50%;
        transition: transform 0.075s ease-out, font-weight 0.075s ease-out;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;

        &:hover {
          transform: scale(1.02);
          font-weight: 700;
        }

        &:active {
          transform: scale(0.975);
          font-weight: 700;
        }

        &:not(:last-child) {
          margin-bottom: 0.8rem;
        }

        a {
          display: flex;
          align-items: center;
          width: 100%;
          min-width: 6.8rem;
          height: 100%;
          padding-right: 0.4rem;
          box-sizing: border-box;
        }
      }

      .sign-out {
        margin-bottom: 2.4rem;
        color: $error;
      }

      .coming-soon {
        margin-left: 0.8rem;
        border-radius: 0.4rem;
        padding: 0.4rem 0.8rem;
        background: $primary-purple;
        color: $white;
        font-weight: 600;
        font-size: 6px;
        line-height: 7px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        pointer-events: none;
      }
    }
  }
}

.socials {
  display: grid;
  grid: 1fr / auto-flow max-content;
  gap: 1.8rem;
  a {
    cursor: pointer;
  }
}

.logo {
  height: 2.6rem;
  margin-bottom: 2rem;
}

.container {
  width: calc(100vw - 3.2rem);
  max-width: 96.6rem;
  padding: 0;
  @media (min-width: 768px) {
    width: calc(100vw - 6.4rem);
  }
}
</style>
