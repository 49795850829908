import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  props: ['chartData', 'height'],
  computed: {
    options() {
      const { chartData: { datasets } } = this;

      // fine tuning charts
      const isTwoDatasets = datasets.length === 2;
      const values1 = datasets.length && [...datasets[0].data];
      const values2 = isTwoDatasets ? [...datasets[1].data] : [];

      const min1 = Math.min(...values1);
      const max1 = Math.max(...values1);
      const container1 = {
        min: min1,
        max: max1,
        lowerRatio: Math.abs(min1) / (Math.abs(min1) + max1),
      };

      const min2 = isTwoDatasets && Math.min(...values2);
      const max2 = isTwoDatasets && Math.max(...values2);
      const container2 = isTwoDatasets
        ? { min: min2, max: max2, lowerRatio: Math.abs(min2) / (Math.abs(min2) + max2) }
        : {};

      const fixBoundaries = () => {
        const { lowerRatio } = container1;
        if (lowerRatio < 0.5) {
          container1.suggestedMin = -container1.max;
        } else if (lowerRatio > 0.5) {
          container1.suggestedMax = Math.abs(container1.min);
        }

        const { lowerRatio: lowerRatio2 } = container2;
        if (lowerRatio2 < 0.5) {
          container2.suggestedMin = -container2.max;
        } else if (lowerRatio2 > 0.5) {
          container2.suggestedMax = Math.abs(container2.min);
        }
      };

      if (isTwoDatasets && (container1.min < 0 || container2.min < 0)) {
        fixBoundaries();
      }

      const yAxes = [
        {
          id: 'A',
          position: 'left',
          gridLines: { borderDash: [2, 2] },
          ticks: {
            suggestedMin: container1.suggestedMin || container1.min,
            suggestedMax: container1.suggestedMax || container1.max,
            beginAtZero: true,
            fontSize: datasets[0].small ? 5 : 12,
            userCallback(value) {
              return value.toLocaleString();
            },
          },
        },
      ];
      if (isTwoDatasets) {
        yAxes.push({
          ...yAxes[0],
          id: 'B',
          position: 'right',
          gridLines: { color: 'rgba(0, 0, 0, 0)', zeroLineColor: 'rgba(0, 0, 0, 0)' },
          ticks: isTwoDatasets && {
            suggestedMin: container2.suggestedMin || container2.min,
            suggestedMax: container2.suggestedMax || container2.max,
            beginAtZero: true,
          },
        });
      }

      return {
        responsive: true,
        aspectRatio: 1.75,
        tooltips: {
          callbacks: {
            label({ datasetIndex, index }, data) {
              const value = data.datasets[datasetIndex].data[index];
              return value.toLocaleString();
            },
          }, // end callbacks:
        }, // end tooltips
        scales: {
          yAxes,
          xAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                padding: 10,
                fontSize: datasets[0].small ? 5 : 12,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      };
    },
  },
  mounted() {
    this.$refs.canvas.height = this.height;

    this.renderChart(
      this.chartData,
      this.options,
    );
  },
};
