<template>
  <div class='page'>
    <div class='main'>
      <header v-if='!showAdvanced'>
        <h1>What public data are you looking for?</h1>
        <p>
          Provide any search keyword, indicator list or country to get access to its public data
        </p>
      </header>
      <header v-else>
        <h1>Advanced Search</h1>
        <p>Filter your search by category, indicator and country</p>
      </header>
      <form @submit.prevent='goToSearch'>
        <div class='input-container'>
          <template v-if='!showAdvanced'>
            <k-input v-model='value' label='' reactive type='search'></k-input>
            <ul :class='["search-suggestions", {loading: isSearching}]'>
              <div v-if='isSearching'>
                <div class='lds-default'>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <template v-else>
                <li v-for='searchTerm in sortedSuggestions'
                    :key='searchTerm'
                    @click='$router.push({name: "Results", query: {q: searchTerm}})'
                    tabindex='0'>
                  {{ searchTerm }}
                </li>
              </template>
            </ul>
          </template>
          <template v-else>
            <div class='advanced-options'>
              <k-input
                v-model='country'
                :optionsDisplay='countries'
                label='Country'
                searchInside='countries'
                type='select'
              ></k-input>
              <k-input
                v-model='category'
                :optionsDisplay='categories'
                label='Category'
                searchInside='categories'
                type='select'
                @search='(val) => debounce(fetchIndicators, 500)({ name: val })'
              ></k-input>
              <k-input
                v-model='indicator'
                :optionsDisplay='indicators'
                label='Indicator'
                searchInside='indicators'
                type='select'
                @search='(val) => debounce(fetchIndicators, 500)({ name: val, category })'
              ></k-input>
            </div>
          </template>
        </div>
        <div v-if='!showAdvanced' class='ctas'>
          <k-button :disabled='!value' short type='submit' variant='primary'>Search</k-button>
          <k-button short variant='link' @click='showAdvanced = true'> Advanced Search</k-button>
        </div>
        <div v-if='showAdvanced' class='ctas advanced'>
          <k-button
            :disabled='!advancedSearchReady'
            class='submit-btn'
            short
            type='submit'
            variant='primary'
            @click='advancedSearch'
          >Submit
          </k-button
          >
          <k-button short variant='tertiary' @click='showAdvanced = false'>Cancel</k-button>
        </div>
      </form>
    </div>

    <div class='suggested'>
      <header>
        <h2>Suggested Articles</h2>
        <k-button class='read-more-btn' short variant='link' @click="$router.push({ name: 'Blog' })"
        >Read More
        </k-button
        >
      </header>
      <ul class='articles' v-if='suggested.length'>
        <li
          v-for='article in suggested'
          :key='article.title'
          class='article'
          @click="$router.push({ name: 'SingleBlog', params: { slug: article.full_slug } })"
        >
          <img :alt='article.name' :src='article.content.image' class='image' />
          <!--          <div class="category">{{ article.category }}</div>-->
          <div class='title'>
            {{ article.name }}
            <img alt='' src='@/assets/read-more-arrow.svg' />
          </div>
        </li>
      </ul>
      <p v-else>No posts yet</p>
    </div>
  </div>
</template>

<script>
import Search from './Search';

export default Search;
</script>

<style lang='scss' scoped src='./Search.scss'>
</style>
